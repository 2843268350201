import React , {useState} from 'react';

import img1 from '../../assets/images/sableAssent/SableAssentOne.jpeg'
// import img2 from '../../assets/images/sableAssent/sableassentthree.jpeg'
import img3 from '../../assets/images/sableAssent/sableAssentfive.jpg'
// import img4 from '../../assets/images/sableAssent/ethereum.jpg'
import img5 from '../../assets/images/sableAssent/sableassentthree.jpeg'
import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        },

        {
            id: 3,
            img : img3,
            class: 'img3'
        },

        {
            id: 5,
            img : img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            // subheading: 'About us',
            heading: 'What Sets Us Apart',
            desc1: 'Our strength lies in unity. By coming together and pooling our resources, we’re creating an economic system that lifts everyone up' ,
            desc2 :'Sable Assent is about much more than just transactions—it’s about building a thriving community, fostering growth, and achieving collective success.'

        }
    )
    return (
        <section className="about">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                {/* <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6> */}
                                <h3 className="heading">{dataBlock.heading}</h3>
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-26">{dataBlock.desc2}</p>
                                    <Button link='/about' title='More About Us' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;