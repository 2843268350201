
import img1 from '../images/team/daryl_edited.png'
import img2 from '../images/team/Otis-Thomas.png'
import img3 from '../images/team/Marlena.png'
import img4 from '../images/team/Melba.png'
import img5 from '../images/team/president.png'
import img6 from '../images/team/Cory-henry.png'
import img7 from '../images/team/doris_edited.png'
import img8 from '../images/team/Aj_edited.png'

const dataTeam = [

    {
        id: 1,
        img: img1,
        name: 'Daryl Speaks',
        position: 'Founder & CEO'
    },
    {
        id: 2,
        img: img2,
        name: 'Otis Thomas',
        position: 'Ambassador Europe'
    },

    {
        id: 3,
        img: img3,
        name: 'Marlena Evans',
        position: 'Operations'
    },

    {
        id: 4,
        img: img4,
        name: 'Melba Jamison',
        position: 'IT Manager'
    },

    {
        id: 5,
        img: img5,
        name: 'President SableAssent',
        position: 'Minerals Committee'
    },
    {
        id: 6,
        img: img6,
        name: 'Cory Henry',
        position: 'Global Ambassador'
    },
    {
        id: 7,
        img: img7,
        name: 'Doris Jeffries',
        position: 'Community Development & Trading'
    },

    {
        id: 8,
        img: img8,
        name: 'AJ Fields',
        position: 'Global Influencer & Media'
    },

   

    

]

export default dataTeam;