

const dataRoadMap = [

    {
        id: 1,
        time: 'ONE',
        title: 'Financial Independence',
        desc: 'We provide the tools and resources that enable individuals and businesses to take control of their economic futures. Our platform is designed to help our community thrive in a rapidly changing world.',
        class: ''
    },
    {
        id: 2,
        time: 'TWO',
        title: 'Cultural Pride',
        desc: 'We celebrate our heritage and work to bring people of African descent closer together. By uniting our efforts, we can create a future that reflects our values and aspirations.',
        class: 'right'
    },

    {
        id: 3,
        time: 'THREE',
        title: 'Innovation',
        desc: 'We leverage blockchain technology to open new doors for economic participation and growth, ensuring that our community stays ahead in the digital age.',
        class: ''
    },

    {
        id: 4,
        time: 'FOUR',
        title: 'Trust',
        desc: 'We operate with transparency and integrity, providing a secure and reliable platform that our users can depend on.',
        class: 'right'
    },
    {
        id: 5,
        time: 'FIVE',
        title: 'Journey Six',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
        class: ''
    },
    {
        id: 6,
        time: 'ONE',
        title: 'Support Black-Owned Businesses',
        desc: 'Every SAC1 transaction fuels the growth of Black-owned businesses, ensuring that wealth circulates within our community.',
        class: ''
    },

    {
        id: 7,
        time: 'TWO',
        title: 'Build Lasting Connections',
        desc: 'Our platform allows you to connect with others who share your values and are committed to building a better future.',
        class: 'right'
    },
    {
        id: 8,
        time: 'THREE',
        title: 'Grow and Prosper Together',
        desc: 'As our community expands, the opportunities for everyone involved will multiply',
        class: ''
    },
    {
        id: 9,
        time: 'ONE',
        title: 'Sable Coin',
        desc: 'The New black digital currency: Lowest Global Transaction costs and fast speed.',
        class: ''
    },
    {
        id: 10,
        time: 'TWO',
        title: 'SAC Digital Wallet',
        desc: 'The Digital Wallet to Build a New Black Wallstreet with a Asset and Real-Estate Tokenization platform and marketplace',
        class: ''
    },

    {
        id: 11,
        time: 'THREE',
        title: 'Sac1 Pos',
        desc: 'Used as a Medium of Exchange for Business Services Gloabal',
        class: 'right'
    },
    {
        id: 12,
        time: 'FOUR',
        title: 'The BBBPT EXPO',
        desc: 'The Official Currency for the building Black Business Expo and All of its Products and Services.',
        class: ''
    },



]

export default dataRoadMap;