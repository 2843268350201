
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio2 = [

    {
        id: 5,
        img: img1,
        title: 'Review',
        desc: 'Sac1 POS will pop up and Click Review to swap'
    },
    {
        id: 6,
        img: img2,
        title: 'Click Swap',
        desc: 'Go ahead and click swap to to swap your matic to to Sac1'
    },
    {
        id: 7,
        img: img3,
        title: 'Transaction is complete',
        desc: 'Congrats on swapping you matic to Sac1. The Transaction should now be a success'
    },
    {
        id: 8,
        img: img4,
        title: 'Withdraw',
        desc: 'To withdraw, Swap Sac1 POS. Use the same Contract above for Matic. NOTE: Only use Polygon/Matic if not you will lose your funds'
    },


]

export default dataPortfolio2;