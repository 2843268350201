import React from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import About2 from '../components/about/About2';
import Counter from '../components/counter/Counter';
import Portfolio from '../components/portfolio/Portfolio';
import Team from '../components/team/Team';
import dataTeam from '../assets/data/dataTeam';
import Footer from '../components/footer/Footer';
import dataPortfolio from '../assets/data/data-portfolio';
import Create from '../components/create/Create';
import Roadmap2 from '../components/roadmap/Roadmap2';
import dataRoadMap from '../assets/data/data-roadmap';
import About4 from '../components/about/About4';





function About(props) {
    return (
        <div className='page-about wrapper'>

            <PageTitle title='About us' />

            <About2 />

            <Roadmap2 data={dataRoadMap}/>

            <About4 />

            <Counter />  

            <Portfolio data={dataPortfolio} />

            <Team data={dataTeam} />

            <Create />

            <Footer />
            
        </div>
    );
}

export default About;