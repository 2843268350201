


const dataFaqs = [
    {
        id: 1,
        title: 'What is Sac1',
        text : 'SAC1 is a public Ethereum based token designed for Black businesses, non-profit organizations, and consumers to exchange goods and services.',
        show: ''
    
    },
    {
        id: 2,
        title: ' Who is SableAssent',
        text : 'SABLEASSENT serves as a Global Transformative Ecosystem designed for Black businesses, non-profit organizations, and consumers to exchange goods and services using our public Ethereum Token, known as “SAC1”.',
        show: ''
    
    },
    {
        id: 3,
        title: 'What have you gotten done, so far?',
        text : 'In the tech department alone, we’ve been moving forward building the SAC Wallet which is now available on both of the most popular mobile platforms: Android, Apple etc',
        show: ''
    
    },
    {
        id: 4,
        title: 'What does "Sable Assent" mean',
        text : 'Sable Assent literally means, “Black Agreement”, our CEO says it means “Black Empowerment”, because if we can all come together and agree on something, (not even everything) then we have a fighting chance of sovereignty in this corrupt world.',
        show: ''
    
    },
    {
        id: 5,
        title: 'Why should i buy Sac1',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 6,
        title: ' Why so many tokens',
        text : 'It may seem like a lot but Sable Coin has a 18 digit decimal. Also, we serve the Black community of which happens to exist in every corner of the world. We intend to have staff and ambassadors in every country served, therefore our accounting department calculates prudency in the reserve of 200 million SAC1 for future staff and ambassadors as needed. This amount is held in cold storage by our custodians until we expand.',
        show: ''
    
    },
    {
        id: 7,
        title: 'Can i do business Over Seas',
        text : 'When you add SAC1 to your business you can provide services to businesses and individuals globally. SAC1 is not bound by exchange rates, interest rates, or transaction charges across international boarders. In fact, there are no borders when it comes to SAC1. Your customers can exchange their country’s fiat currency into (and out of) SAC1 by accessing one of our many exchange listings on LAToken.com, p2pb2b.com, and btfxt.com.',
        show: ''
    
    },
    {
        id: 8,
        title: 'Who accepts Sac1',
        text : 'The objective of SableAssent is to create a global community that builds wealth by use of the SAC1 Token. The SAC1 token was created to establish wealth in the global black community. There are currently 1.5 billion black people in the world who annually spend over a trillion dollars. Currently, a small percentage of that the trillion dollars stays in the black economy. Our mission is to create an ecosystem that circulates funds, builds generational wealth, and supports global black communities with an infrastructure by using the SAC1 Token.',
        show: ''
    
    },


   

   
]

export default dataFaqs;