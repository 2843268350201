
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Go to the wallet of your choice',
        desc: 'This can be any Wallet of your choice. Coinbase Wallet, Meta Mask, Trust Wallet and even EnKrypt etc...'
    },
    {
        id: 2,
        img: img2,
        title: 'Choose Polygon',
        desc: 'Choose The Polygon for the Blockchain and Matic as the coin. NOTE: you need to buy as much Matic in Sac1 POS. as of 4/31 .98-105 Matic/Sac1'
    },
    {
        id: 3,
        img: img3,
        title: 'Go to Uniswap',
        desc: 'Go to uniswap.org and Launch App to coonet your wallet in pink'
    },
    {
        id: 4,
        img: img4,
        title: 'Choose SWAP',
        desc: 'SWAP the amount of Matic for Sac1 POS on Matic network (NOT ETH). Paste this adress: 0xedd666802003d178c416b4e5dd6a82f76c2c7554'
    },


]

export default dataPortfolio;