import React, { useState } from "react";

// import PageTitle from "../components/pagetitle/PageTitle";
// import Counter from "../components/counter/Counter";
// import Footer from "../components/footer/Footer";
// import Faqs from "../components/faqs/Faqs";
// import dataFaqs from "../assets/data/data-faq";
// import Speciality from "../components/speciality/Speciality";
// import dataBox from "../assets/data/data-box";

import img1 from "../assets/images/sableAssent/SableAssentTwo.jpeg";
// import img2 from "../assets/images/layouts/about-02.png";
import img3 from "../assets/images/sableAssent/sableAssentsix.jpg";
// import img4 from "../assets/images/layouts/about-04.png";
import img5 from "../assets/images/sableAssent/sableassenteight.jpg";

function VisionsMission(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },

    {
      id: 3,
      img: img3,
      class: "img3",
    },

    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);
  return (
    <div className="page-visions wrapper mt-50">
      {/* <PageTitle title='Visions & Mission' /> */}
      {/* <Speciality data={dataBox} /> */}

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h3 className="heading wow" data-splitting>
                  Our Pillars of Success
                </h3>
                <h6 className="sub-heading">
                  <span>Empowerment</span>
                </h6>

                <p className="mb-17">
                  We equip our community with the tools for financial
                  independence, ensuring a stable and prosperous future.
                </p>
                <h6 className="sub-heading">
                  <span>Cultural Unity</span>
                </h6>
                {/* <h3 className="heading wow" data-splitting>Our Mission</h3> */}
                <p className="mb-17">
                  We take pride in our heritage and work to unite people of
                  African descent. Together, we’re shaping a better tomorrow.
                </p>
                <h6 className="sub-heading">
                  <span>Innovation</span>
                </h6>
                {/* <h3 className="heading wow" data-splitting>Our Mission</h3> */}
                <p className="mb-17">
                Through cutting-edge blockchain technology, we’re opening new doors for economic participation and growth
                </p>{" "}
                <h6 className="sub-heading">
                  <span>Integrity</span>
                </h6>
                {/* <h3 className="heading wow" data-splitting>Our Mission</h3> */}
                <p>
                  Trust and transparency are the foundation of our platform. We’re committed to providing a secure, reliable environment for all users
                </p>
              </div>

            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  {dataImg.map((idx) => (
                    <img
                      key={idx.id}
                      className={idx.class}
                      src={idx.img}
                      alt="SableAssent"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Counter />   */}

      {/* <Faqs data={dataFaqs} /> */}

      {/* <Footer /> */}
    </div>
  );
}

export default VisionsMission;
