
import img1 from '../images/layouts/project-01.png'
import img2 from '../images/layouts/project-02.png'


const dataProject = [

    {
        id: 1,
        img: img1,
        title: '3D Digital Artwork'
    },
    {
        id: 2,
        img: img2,
        title: 'King Of Pirates'
    },



    

]

export default dataProject;