import React, { useState } from "react";
import PropTypes from "prop-types";



import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

Roadmap2.propTypes = {
  data: PropTypes.array,
};

function Roadmap2(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Our Values",
    heading: "What We Believe In",
  });
  return (
    <section className="roadmap s2">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading pdb">{dataBlock.heading}</h3>
            </div>

            <div className="roadmap__main s1">
              <div className="roadmap-swiper">
                {data.slice(0, 4).map((idx) => (
                  <div className="roadmap-box">
                    <div className="time">{idx.time}</div>
                    <div className="content">
                      <h5 className="title">{idx.title}</h5>
                      <p className="text">{idx.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap2;
