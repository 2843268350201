import React from 'react';
import dataBox from '../assets/data/data-box';
import dataFaqs from '../assets/data/data-faq';
import dataPortfolio from '../assets/data/data-portfolio';
import dataPortfolio2 from '../assets/data/data-portfolio2';
// import dataRoadMap from '../assets/data/data-roadmap';
import dataTestimonials from '../assets/data/data-testimonials';
// import dataProject from '../assets/data/dataProject';
import dataTeam from '../assets/data/dataTeam';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Portfolio from '../components/portfolio/Portfolio';
// import Project from '../components/project/Project';
// import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import Team from '../components/team/Team';
import Testimonials from '../components/testimonials/Testimonials';
import VisionsMission from './VisionsMission';
// import Roadmap2 from '../components/roadmap/Roadmap2';
import dataRoadMap from '../assets/data/data-roadmap';
import Roadmap3 from '../components/roadmap/Roadmap3';
import Roadmap4 from '../components/roadmap/Roadmap4';
// import SpecialityTwo from '../components/speciality/SpecialityTwo';



function Home01(props) {

    return (
        <div className="home-1 wrapper">
            
            <Banner />

            <About />

            {/* <Speciality data={dataBox} /> */}

            <VisionsMission />

            <Speciality data={dataBox} />

            <Roadmap3 data={dataRoadMap}/>

            {/* <SpecialityTwo /> */}

            <Roadmap4 data={dataRoadMap}/>

            <Portfolio data={dataPortfolio} data2={dataPortfolio2} />


           
            {/* <Roadmap  data={dataRoadMap} /> */}

            <Team data={dataTeam} />

            {/* <Testimonials data={dataTestimonials} /> */}

            <Faqs data={dataFaqs} />

            <Create />

            <Footer />
        </div>
        

    );
}

export default Home01;