const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        
    },
   
    {
        id: 4,
        name: 'About',
        links: '/about',
 
    },

    {
        id: 6,
        name: 'Team',
        links: '/team',
        
    },
    {
        id: 7,
        name: 'Contact',
        links: '/contact',
    },

    
]

export default menus;