import React , {useState} from 'react';

import Button from '../button/Button';
// import Partner from '../partner/Partner';

import img from '../../assets/images/layouts/advanced-technological-robot-interacting-with-money-finance.jpg'
// import Roadmap2 from '../roadmap/Roadmap2';


function About2(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'Who is SableAssent?',
            

            desc1: 'At Sable Assent, we are more than just a platform—we are a community dedicated to building a brighter future together',
            desc2: 'Our mission is to create a thriving economic system that benefits people of African descent across the globe. Through our innovative digital currency, SAC1, we are working to ensure that wealth is circulated within our communities, strengthening our economic foundation and creating opportunities for growth.',
           
        }
    )
    const [dataBlock2] = useState(
        {
            subheading: 'Vision',
            heading: 'Our Vision',
            

            desc1: 'We envision a world where communities of African descent are united by a shared commitment to economic success',
            desc2: 'By working together, we believe we can overcome past challenges and build a prosperous future for generations to come. Sable Assent is dedicated to connecting individuals and businesses through a network that fosters growth, stability, and shared success.',
           
        }
    )

    
    return (
        <div className='our-vision-v1'>
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading pd">{dataBlock.heading}</h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src={img} alt="SableAssent" width={"70%"}/>
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    <Button title='More About Us' link='/about' />
                                </div>

                                {/* <Partner /> */}
                            </div>
                        </div>
                    </div>
                </section>
                        <section className="about s2 mb-50">
                        <div className="shape"></div>
                        <div className="shape right"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="block-text center pd-0">
                                        <h6 className="sub-heading"><span>{dataBlock2.subheading}</span></h6>
                                        <h3 className="heading pd">{dataBlock2.heading}</h3>
                                            
                                    </div>
    
                                    <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                        
                                        <p className="mb-17">{dataBlock2.desc1}</p>
                                        <p className="mb-30">{dataBlock2.desc2}</p>
                                        <Button title='More About Us' link='/about' />
                                    </div>
    
                                    {/* <Partner /> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    
                    </div>
    );
}

export default About2;